@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root,
  .light {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;

    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;

    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;

    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;

    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 10% 3.9%;

    --radius: 0.5rem;

    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;

    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;

    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;

    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;

    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;

    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;

    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;

    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;

    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

/* *::-webkit-scrollbar {
    width: 10px;
  }

  *::-webkit-scrollbar-track {
    @apply bg-white dark:bg-black;
  }

  *::-webkit-scrollbar-thumb {
    @apply bg-zinc-300 dark:bg-zinc-800 ;
  } */

* {
  scrollbar-width: thin;
  scrollbar-color: red;
}

/* Chrome, Edge, and Safari */

.foo {
  @apply bg-clip-text text-[78px] not-italic font-normal leading-[70.5px] tracking-[0.6px];
  background: linear-gradient(315deg, #a2c754 0%, #c75454 33.33%, #9454c7 66.67%, #54b2c7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.win {
  @apply bg-clip-text text-[78px] italic font-bold leading-[70.5px] tracking-[0.6px];
  background: linear-gradient(315deg, #a2c754 0%, #c75454 33.33%, #9454c7 66.67%, #54b2c7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* font-family: Inter; */
}

.take {
  @apply text-[#B4B4B4] text-[78px] not-italic font-medium leading-[70.5px] tracking-[0.6px];
}

.backgroundgradient {
  background: linear-gradient(90deg, rgba(1, 1, 1, 0.6) 0%, rgba(1, 1, 1, 0.46) 59.76%, rgba(1, 1, 1, 0) 100%);
}

.icon-placeholder {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex: 40px 0;
  margin-right: 20px;
}

.text-placeholder {
  border-radius: 5px;
  width: 200px;
  height: 20px;
  flex: 1;
}

.refresh {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.bg-animation {
  background: linear-gradient(115deg, #7dd3fc, #c084fc);
  background-size: 400% 400%;

  animation: AnimationName 2s ease infinite;
}

@keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

html.lenis {
  height: auto;
  overflow-x: hidden;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

.pricing-splide-track {
  overflow: visible !important;
}

.pulse {
  animation: pulse-animation 2s infinite;
  /* make the anumation subtle */
  animation-timing-function: ease-in-out;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(59, 130, 246, 0.2);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(59, 130, 246, 0);
  }
}

.input-label {
  @apply text-[#34d2ca] text-lg mb-2;
}

.input[data-variant="underline"] {
  border-bottom: rem(2px) solid;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}
.input[data-variant="underline"]:focus {
  border-color: var(--mantine-color-blue-filled);
}

.star {
  width: 30px;
  height: 30px;
  position: absolute;
}

.star:before,
.star:after {
  content: "";
  display: block;
  width: 20%;
  height: 100%;
  border-radius: 20px;
  background: #222;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.star.alt:before,
.star.alt:after {
  background: #222;
}

.star.alt2:before,
.star.alt2:after {
  background: #222;
}

.star:after {
  transform: translate(-50%, -50%) rotate(90deg);
}

.sparkle {
  width: 10px;
  height: 10px;
  position: absolute;
  border-radius: 50%;
  background: #222;
}

.sparkle.alt {
  background: #222;
}

.sparkle.alt2 {
  background: #222;
}

.medium {
  transform: scale(0.5);
}

.small {
  transform: scale(0.1);
}

/* Animations */
@keyframes pulse {
  0% {
    transform: scale(0.45);
  }
  90% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(0.45);
  }
}

.pulse {
  animation: pulse 1s linear infinite;
}

.pulse-1 {
  animation: pulse 1.1s 300ms linear infinite;
}

.pulse-2 {
  animation: pulse 0.8s 600ms linear infinite;
}

.pulse-3 {
  animation: pulse 1.3s 900ms linear infinite;
}

@keyframes glow {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.loading-bar {
  position: fixed;
  left: 64px;
  width: 4px;
  height: 100%;
  background-color: transparent;
  overflow: hidden;
  animation: moveLoadBar 10s cubic-bezier(0.09, 0.89, 0.7, 0.71) forwards;
}

.loading-bar::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0;
  height: 20%;
  width: 3px;

  background-color: #ec4899;
  box-shadow: 0 50px 200px 200px #ec4899, 0 -10px 30px 15px #ec4899;
  animation: moveLoadBar 10s cubic-bezier(0.09, 0.89, 0.7, 0.71) infinite;
}

@keyframes moveLoadBar {
  0% {
    top: -10%;
  }
  100% {
    top: 110%;
  }
}

.loading-bar.hidden {
  display: none;
}

.price-image {
  mix-blend-mode: color-dodge;
  animation: priceScaleIn 5.8s cubic-bezier(0.45, 0, 0.44, 1);
}

@keyframes priceScaleIn {
  0% {
    transform: scale(0.9) translateY(40px);
  }
  100% {
    transform: scale(1) translateY(0);
  }
}

.price-dots {
  animation: 5.8s cubic-bezier(0.45, 0, 0.55, 1) 0s 1 normal none running PlBWC;
}

@keyframes PlBWC {
  0% {
    transform: scale(0.9) translateY(40px);
  }
  100% {
    transform: scale(1) translateY(0px);
  }
}

.scan-animation {
  width: 130px;
  height: 130px;
  margin: 20px;
  position: relative;
}
.scan-animation::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 2px;
  background: #18c89b;
  box-shadow: 0 0 70px 10px #18c89b;
  clip-path: inset(0);
  animation: x 1s ease-in-out infinite alternate, y 2s ease-in-out infinite;
}

@keyframes x {
  to {
    transform: translateX(-100%);
    left: 100%;
  }
}

@keyframes y {
  33% {
    clip-path: inset(0 0 0 -100px);
  }
  50% {
    clip-path: inset(0 0 0 0);
  }
  83% {
    clip-path: inset(0 -100px 0 0);
  }
}

.vignette:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: radial-gradient(ellipse at center, rgb(0 0 0 / 0%) 0%, rgb(0 0 0) 80%, rgb(8 0 0) 100%);
  border-radius: 0%;
  position: absolute;
  top: 0;
  left: 0;
}

.product-game-card__overlay {
  align-items: center;
  background-color: #000c;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition-duration: 0.15s;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
}
.product-game-card:focus-visible .product-game-card__overlay,
.product-game-card:hover .product-game-card__overlay {
  opacity: 1;
}

.brand-card {
  --tw-linear-gradient-to: var(--tw-gradient-to);
  --tw-radial-gradient-from: var(--tw-gradient-from);
  --tw-radial-gradient-to: var(--tw-gradient-to);
  --tw-radial-gradient-stops: var(--tw-radial-gradient-from), var(--tw-radial-gradient-to);
  background-image: radial-gradient(120% 180% at 50% 200%, var(--tw-gradient-from), var(--tw-gradient-to)),
    radial-gradient(90% 50% at 50% 130%, var(--tw-gradient-from), var(--tw-gradient-to));
  background-image: radial-gradient(120% 180% at 50% 200%, var(--tw-radial-gradient-stops)),
    radial-gradient(90% 50% at 50% 130%, var(--tw-radial-gradient-stops));
  min-height: 400px;
}
.brand-card .marquee > .flex {
  align-items: center;
}
.media-bounce-grid .group-0 {
  padding-top: 100%;
  position: relative;
  top: 15%;
}
.media-bounce-grid .group-0.bounce-col {
  animation-delay: 0.1s;
}
.media-bounce-grid .group-5 {
  padding-top: 100%;
  position: relative;
  top: 5%;
}
.media-bounce-grid .group-5.bounce-col {
  animation-delay: 1.3s;
}
.media-bounce-grid .group-1 {
  position: relative;
  top: 20%;
}
.media-bounce-grid .group-1.bounce-col {
  animation-delay: 0.3s;
}
.media-bounce-grid .group-2 {
  position: relative;
  top: 10%;
}
.media-bounce-grid .group-2.bounce-col {
  animation-delay: 0.5s;
}
.media-bounce-grid .group-3.bounce-col {
  animation-delay: 0;
}
.media-bounce-grid .group-4 {
  position: relative;
  top: 8%;
}
.media-bounce-grid .group-4.bounce-col {
  animation-delay: 1s;
}
.bounce-col {
  animation: bounce-71b6f6d3 3s cubic-bezier(0.4, 0, 0.2, 1) infinite alternate;
}
@keyframes bounce-71b6f6d3 {
  0% {
    transform: translateZ(0);
  }
  to {
    transform: translate3d(0, 20%, 0);
  }
}

.bg-brand-blue-bg {
  /* --tw-bg-opacity:1; */ /* background-color:#12101a; */
  background-color: rgb(18 16 26 / var(--tw-bg-opacity));
}

#frame {
  -ms-zoom: 0.75;

  -webkit-transform-origin: 0 0;
}

.animated-gradient-button {
  line-height: 100px;
  color: #222;
  /* color: rgba(255, 255, 255, 0.9); */
  border-radius: 50px;
  /* background: linear-gradient(-45deg, #ffa63d, #ff3d77, #338aff, #3cf0c5); */
  /* background-size: 600%; */

  /* animation: gradientButtonAnimation 16s linear infinite; */
}

@keyframes gradientButtonAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* writingMode: "vertical-rl",
textOrientation: !isMd ? "unset" : "sideways", */

.pricing-table-header {
  writing-mode: vertical-rl;
  text-orientation: sideways;
}

@media (min-width: 768px) {
  .pricing-table-header {
    writing-mode: unset;
    text-orientation: unset;
  }
}

@layer utilities {
  .h-s-full {
    height: 100svh;
  }
}
